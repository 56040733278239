import { css } from '@emotion/core'

export default () => css`
.content-slider-section {
    padding: 80px 0 100px;
    background: #F2EEEB; 
}

.content-slider-section.product-slider-section .slider-card-image-block {
    background: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
}

.content-slider-section.product-slider-section .slider-card-title {
    color: #191919;
    font-weight: 200;
}

.content-slider-section .section-container {
    padding: 0 35px;
    position: relative;
}

.content-slider-section .content-slider-header {
    margin-bottom: 36px;
}

.content-slider-section .content-slider-header-title {
    color: #232323;
    font-family: 'Switzer-Semibold', sans-serif;
    font-size: 42px;
    line-height: 52px;
}

.content-slider-section .content-slider-header-description {
    margin-top: 10px;
    color: #060606;
    font-size: 14px;
    line-height: 22px;
}
  
.content-slider-section .section-slider-row {
    width: calc(100% + 28px);
    margin-left: -14px;
}

.content-slider-section .section-slider-row.slider-mobile-row .slider-card-image-block {
    padding: 0;
}
  
.content-slider-section .section-slider-row.slider-mobile-row .slider-card-image-block .global-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.content-slider-section .section-slider-row.slider-mobile-row .slick-arrow {
    display: none !important;
}
  
.content-slider-section .section-slider-row.slider-mobile-row .slick-dots {
    display: none !important;
}
  
.content-slider-section .slick-slide {
    padding: 0 14px;
}
  
.content-slider-section .slick-arrow {
    width: 51px;
    height: 51px;
    top: -70px;
    background: #232323;
}

.content-slider-section .slick-arrow:hover {
    background: #232323;
}

.content-slider-section .slick-arrow::before {
    color: #fff;
}

.content-slider-section .slick-arrow.slick-prev {
    left: auto;
    right: 100px;
    background: #232323 url(https://ik.imagekit.io/2fqsvlgz3q/gently/slider-arrow-left.png?updatedAt=1731437246543) center center no-repeat;
    z-index: 2;
    background-size: 26px auto;
}

.content-slider-section .slick-arrow.slick-prev::before {
    opacity: 1;
}
    
.content-slider-section .slick-arrow.slick-next {
    right: 20px;
    background: #232323 url(https://ik.imagekit.io/2fqsvlgz3q/gently/slider-arrow-right.png?updatedAt=1731437271780) center center no-repeat;
    background-size: auto;
    background-size: 26px auto;
    opacity: 1;
}

.content-slider-section .slick-arrow.slick-next::before {
    opacity: 1;
}
    
.content-slider-section .slick-arrow.slick-disabled {
    pointer-events: none;
    opacity: 1;
}
    
.content-slider-section .slick-arrow.slick-disabled::before {
    opacity: 1;
}
    
.content-slider-section .slick-arrow.slick-disabled:focus {
    background: #232323; 
}
  
.content-slider-section .slick-dots {
    width: 340px;
    height: 6px;
    display: flex !important;
    left: 50%;
    bottom: -50px;
    background: #fff;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.content-slider-section .slick-dots li {
    width: 100%;
    height: auto;
    margin: 0;
}

.content-slider-section .slick-dots li.slick-active button {
    background: #232323;
}

.content-slider-section .slick-dots li button {
    width: 100%;
    height: 6px;
    margin: 0;
    padding: 0;
    background: #fff;
}

.content-slider-section .slick-dots li button::before {
    display: none;
}

@media (max-width: 767px) {
    .content-slider-section {
      padding: 40px 0;
    }
    
    .content-slider-section .section-container {
        padding-left: 24px;
        padding-right: 0;
    }
    
    .content-slider-section .content-slider-header-title {
        font-size: 30px;
        line-height: 38px;
    }
    
    .content-slider-section .section-slider-row.slider-mobile-row .slick-dots {
        display: flex !important;
    }
    
    .content-slider-section .slick-slide {
        padding: 0 16px;
    }
    
    .content-slider-section .slick-arrow {
        display: none !important;
    }
    
    .content-slider-section .slick-dots {
        width: 70%;
    } 
}

@media (min-width: 768px) and (max-width: 1023px) {
    .content-slider-section {
      padding: 60px 0;
    }
    
    .content-slider-section .section-container-1140 {
        padding-left: 40px;
    }
    
    .content-slider-section .slick-slide {
        padding: 0 16px;
    }
    
    .content-slider-section .slick-arrow {
        display: none !important;
    }
    
    .content-slider-section .slick-dots {
        width: 70%;
    }
}

@media (min-width: 1024px) and (max-width: 1399px) {
    .content-slider-section .section-container-1140 {
      padding-left: 50px;
    }
}

.slider-card {
    width: 250px;
}

.slider-card.category-card {
    padding: 0;
}

.slider-card.category-card .slider-card-image-block {
    padding: 0;
}

.slider-card.category-card .global-image {
    object-fit: cover;
}

.slider-card .slider-card-image-block {
    width: 100%;
    height: 338px;
    position: relative;
    background: #fff;
}

.slider-card .slider-card-image-block .content-tag {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
}
    
.slider-card .slider-card-image-block .global-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
      
.slider-card .slider-card-image-block .global-image.product-image {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: unset;
}

.slider-card .slider-card-title {
    margin-top: 24px;
    color: #121010;
    font-family: "Switzer-Semibold", sans-serif;
    font-size: 22px;
}

.slider-card .slider-card-user,
.slider-card .slider-card-user-link {
    margin-top: 10px;
    color: #191919;
    font-family: "Switzer-Regular", sans-serif;
    font-size: 17px;
    letter-spacing: 2px; 
}

.slider-card .slider-card-description,
.slider-card .slider-card-shop {
    margin-top: 8px;
    color: #676767;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.64px;
}

.slider-card .slider-card-shop {
    margin-top: 0;
}

.slider-card .slider-card-price {
    margin-top: 4px;
    color: #191919;
    font-family: "Switzer-Regular", sans-serif;
    font-size: 17px;
    line-height: 33px;
    letter-spacing: 2px;
}

.slider-card .slider-card-link-holder {
    margin-top: 30px;
}

.slider-card .slider-card-price-holder {
    margin-top: 30px;
    color: #191919;
    font-family: "Switzer-Semibold", sans-serif;
    font-size: 17px;
    line-height: 25px;
}

.slider-card .slider-card-link {
    font-size: 14px;
    text-decoration: underline;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.slider-card .slider-card-link:hover {
    color: #121010;
    text-decoration: none;
}
  
@media (max-width: 767px) {
    .slider-card .slider-card-image-block {
        height: 300px;
    }
    .slider-card .slider-card-title {
        font-size: 18px;
    }
    .slider-card .slider-card-description {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
    }
    .slider-card .slider-card-link-holder {
      margin-top: 20px; }
    .slider-card .slider-card-link {
      font-size: 12px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .slider-card .slider-card-image-block {
      height: 300px; }
    .slider-card .slider-card-title {
      font-size: 18px; }
    .slider-card .slider-card-description {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px; }
    .slider-card .slider-card-link-holder {
      margin-top: 20px; }
    .slider-card .slider-card-link {
      font-size: 12px; } }



@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

`